import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { StorageService } from 'app/services/storage.service';
import { CountrySettingsService } from 'app/services/country-settings.service';

@Injectable({
    providedIn: 'root',
})
export class HealthCheckService {
    private readonly http = inject(HttpClient);
    private readonly storageService = inject(StorageService);
    private readonly countrySettingsService = inject(CountrySettingsService);

    doHealthCheck() {
        const url = this.countrySettingsService.countrySettings?.apiUrl.replace('api.', 'health-check.').replace('/v2', '');

        if (url && this.storageService.token) {
            this.http
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${this.storageService.token}`,
                    },
                })
                .subscribe();
        }
    }
}
